import React from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";

const ImpressumInfo = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          // don't show the menu on this page
          headerClassName: "site-header--menu-none",
          headerButton: (
            <>
              <Link
                to="/#"
                className="element btn bg-gradient-1 btn-text-white header-btn rounded-5"
              >
                Zum Erstgespräch
              </Link>
            </>
          ),
        }}
      >
        <div className="pt-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">Impressum</h2>
                  <p className="font-size-7 mb-0">
                    gemäß § 63 GewO, § 5 ECG, § 25 Abs 5 MedienG
                  </p>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-lg-9 pr-lg-13 pr-xl-0">
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">
                      Gewerbe- und medienrechtlicher Verantwortlicher
                    </h5>
                    <div className="font-size-5 heading-default-color mb-0">
                      <div className="mb-5">
                        Thesmo Legal Tech Solutions GmbH, FN 610462p
                        <br />
                        Schönbrunner Straße 20/8, 1050 Wien, Österreich
                      </div>
                      <div className="mb-5">
                        Email: office@konzierge.at
                        <br />
                        ‍Tel: +43 (0) 660 44 22 111
                      </div>
                      <div className="mb-5">
                        Geschäftsführer: Mag. Alexander Kryza und Johann Mader,
                        BA <br />
                        Firmenbuchgericht: Handelsgericht Wien
                      </div>
                      <div className="mb-5">
                        Unternehmensgegenstand: Dienstleistungen in der
                        automatischen Datenverarbeitung und Informationstechnik
                        <br />
                        UID: ATU75851767
                      </div>
                      Mitglied der WKO, Fachgruppe Unternehmensberatung,
                      Buchhaltung und IT (UBIT)
                      <br />
                      Gewerberechtliche Aufsichtsbehörde: Magistratisches
                      Bezirksamt der Stadt Wien
                      <br />
                      Gewerberechtliche Vorschriften: Gewerbeordnung
                      (www.ris.bka.gv.at)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default ImpressumInfo;
